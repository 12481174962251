


















import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  props: {
    participants: {
      type: Array,
      required: true,
      default: "",
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      mails: "",
      tempMails: [] as string[],
    });

    const closeDialog = () => {
      emit("close-dialog");
    };

    const getMails = () => {
      state.tempMails = props.participants
        ?.map((item: any) => item.email)
        .filter((value, index, self) => self.indexOf(value) === index);
      state.mails = state.tempMails.join(", ");
    };

    watch(
      () => props.participants,
      () => {
        getMails();
      }
    );

    onMounted(getMails);

    return { state, closeDialog };
  },
});
